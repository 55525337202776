<template>
  <div class="aboutChunk">
    <div class="title flex">
      <div class="flex">
        <img src="../../assets/aboutOk.png" alt="" />
        <div class="item">
          <div>{{ detection.detection_pass_count }}</div>
          <div>合格</div>
        </div>
      </div>
      <div class="flex">
        <img src="../../assets/aboutPass.png" alt="" />
        <div class="item">
          <div>{{ detection.detection_no_pass_count }}</div>
          <div>不合格</div>
        </div>
      </div>
    </div>
    <div class="aboutScroll">
      <scroll :type="4" />
    </div>
  </div>
</template>

<script>
import scroll from './scroll.vue'
import { getDetectionPassCount } from '@api/index'
export default {
  name: 'aboutChunk',
  components: { scroll },
  data () {
    return {
      detection: {}
    }
  },
  created () {},
  methods: {
    getDetectionPassCount (type) {
      getDetectionPassCount({
        market_id: this.$route.query.id,
        type: type
      }).then(res => {
        this.detection = res.data
      })
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.aboutChunk {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 1rem;
    // border: 1px solid red;
    justify-content: space-evenly;
    padding-top: 0.1rem;
    box-sizing: border-box;
    font-size: 0.24rem;
    img {
      width: 1rem;
      height: 0.85rem;
    }
    .item {
      flex: 1;
      text-align: center;
      color: #07dbff;
      margin-left: 0.2rem;
    }
  }
  .aboutScroll {
    width: 100%;
    height: calc(100% - 1rem);
  }
}
</style>
