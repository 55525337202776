<template>
  <div class="login justifyCenter flex">
    <div class="flex border">
      <div class="right flex">
        <img src="../assets/loginImg.png" alt="" />
      </div>
      <div class="left">
        <div class="title">智慧农贸系统平台</div>
        <el-input
          v-model="ruleForm.username"
          prefix-icon="el-icon-user"
          style="width:60%"
          placeholder="请输入账号"
        ></el-input>
        <el-input
          v-model="ruleForm.password"
          prefix-icon="el-icon-lock"
          show-password
          style="width:60%"
          placeholder="请输入密码"
        ></el-input>
        <div class="btn" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      ruleForm: { username: '', password: '' }
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    login () {
      this.getUserInfo(this.ruleForm)
        .then(res => {
          this.$message({
            message: '登录成功',
            type: 'success',
            customClass: 'message'
          })
          this.$router.replace('/')
        })
        .catch(err => {
          this.$message({
            message: err.msg,
            type: 'error',
            customClass: 'message'
          })
        })
    }
  }
}
</script>

<style lang="less">
.login {
  width: 100%;
  height: 100%;
  background-image: url('../assets/loginBg.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  .flex {
    justify-content: center;
  }
  .border {
    width: 10rem;
    height: 6rem;
  }
  .right {
    background: rgb(59, 208, 227);
    img {
      width: 4.8rem;
      height: 3.75rem;
      margin-top: 0.6rem;
    }
  }
  .left {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.6rem 0;
    box-sizing: border-box;
    .title {
      width: 60%;
      text-align-last: justify;
      font-size: 0.37rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #1a8666;
    }
    .btn {
      width: 60%;
      height: 0.55rem;
      line-height: 00.55rem;
      background: #1a8666;
      border-radius: 0.3rem;
      font-size: 0.25rem;
      letter-spacing: 0.15rem;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
  .right,
  .left {
    width: 50%;
    height: 100%;
  }
}
</style>
