<template>
  <div class="videohls flex">
    <div class="videoMax" ref="videoMax">
      <span class="title">{{ title }}</span>
    </div>
    <div class="videoList">
      <carousels :palyHls="palyHls" type="videohls" />
    </div>
  </div>
</template>

<script>
import carousels from './carousels.vue'
export default {
  name: 'videohls',
  components: {
    carousels
  },
  data () {
    return {
      title: ''
    }
  },
  created () {},
  methods: {
    palyHls (url, title) {
      if (this.videoMax) {
        this.videoMax.dispose()
      }
      this.title = title
      this.appendChild()
      // 播放器配置
      const options = {
        bigPlayButton: false,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        controlBar: false,
        muted: true,
        autoplay: true,
        controls: false // 是否显示控件
      }
      this.videoMax = this.$videojs('video', options, () => {
        this.videoMax.src({
          src: url,
          type: 'application/x-mpegURL' // 修改这个type值
        })
      })
    },
    appendChild () {
      const video = document.createElement('video')
      video.id = 'video'
      video.style = 'width:100%;height:100%;background:#333;object-fit: fill;'
      this.$refs.videoMax.appendChild(video)
    }
  },
  beforeDestroy () {
    if (this.videoMax) {
      this.videoMax.dispose()
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.videohls {
  width: 100%;
  height: 100%;
  font-size: 0.15rem;
  flex-direction: column;
  .videoMax {
    width: 100%;
    height: 66%;
    position: relative;
    .title {
      position: absolute;
      left: 6px;
      bottom: 6px;
      font-size: 16px;
      color: #fff;
      background-color: rgba(0,0,0,0.6);
      padding: 3px;
      z-index: 99;
    }
  }
  .videoList {
    width: 100%;
    height: 30%;
  }
}
</style>
