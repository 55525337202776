<template>
  <div
    class="scroll"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <dv-scroll-board :config="config" ref="config" />
  </div>
</template>

<script>
import { getTradingLog, getRanking, getDetectionIn } from '@api/index'
export default {
  name: 'scroll',
  props: ['type', 'scrollData'],
  data () {
    return {
      config: {},
      limit: 10,
      loading: false,
      configData: [],
      lastPage: 1,
      page: 1
    }
  },
  watch: {
    scrollData (val) {
      if (val) {
        this.setData()
      }
    }
  },
  created () {
    if (this.type === 3) {
      this.getTradingLog()
    }
    if (this.type === 4) {
      this.getDetectionInformation()
    }
  },
  methods: {
    setData () {
      switch (this.type) {
        case 1:
          this.config = {
            header: ['热销笔数'],
            data: this.scrollData,
            headerBGC: 'rgb(32, 110, 212)',
            oddRowBGC: '',
            evenRowBGC: '',
            rowNum: 4,
            align: ['left', 'right']
          }
          break
        case 2:
          this.config = {
            header: ['热销金额'],
            data: this.scrollData,
            headerBGC: 'rgb(32, 110, 212)',
            oddRowBGC: '',
            evenRowBGC: '',
            rowNum: 4,
            align: ['left', 'right']
          }
          break
      }
    },
    getTradingLog () {
      if (this.limit === 10) {
        this.loading = true
      }
      getTradingLog({
        limit: this.limit,
        market_id: this.$route.query.id
      }).then(res => {
        let configData = this.configData
        res.data.forEach(item => {
          configData.push([
            item.product_name,
            item.price + '元',
            item.amount + item.unit,
            item.total_price + '元',
            item.booth_name
          ])
        })
        this.configData = configData
        this.config = {
          headerBGC: 'rgb(32, 110, 212)',
          oddRowBGC: '',
          evenRowBGC: '',
          header: ['商品名称', '单价', '重量', '金额', '摊铺'],
          data: this.configData,
          align: ['center', 'center', 'center', 'center', 'center']
        }
        if (this.limit === 10) {
          this.loading = false
        }
        if (configData.length === 0) {
          return clearTimeout(this.time)
        }
        this.time = setTimeout(() => {
          this.limit += 10
          this.getTradingLog()
        }, 60000)
      })
    },
    getRanking () {
      getRanking({
        page: this.page,
        market_id: this.$route.query.id,
        ranking_type: this.type === 5 ? 1 : 2
      }).then(res => {
        let configData = this.configData
        res.data.data.forEach(item => {
          let i = ''
          if (item.seller_shops) {
            for (let num = 0; num < item.star; num++) {
              i += '<i class="el-icon-star-on"></i>'
            }
            for (let num = 0; num < 5 - item.star * 1; num++) {
              i += '<i class="el-icon-star-off"></i>'
            }
          }
          configData.push([item.seller_shops ? item.seller_shops.name : '', i])
        })
        this.configData = configData
        this.config = {
          data: this.configData,
          rowNum: 4,
          oddRowBGC: '',
          evenRowBGC: '',
          align: ['left', 'right']
        }
        this.lastPage = res.data.last_page
        this.page += 1
        if (this.page >= this.lastPage) {
          return clearTimeout(this.rangking)
        }
        this.rangking = setTimeout(this.getRanking(), 60000)
      })
    },
    getDetectionInformation () {
      getDetectionIn({
        page: this.page,
        market_id: this.$route.query.id
      }).then(res => {
        this.lastPage = res.data.last_page
        let configData = this.configData
        res.data.data.forEach(item => {
          configData.push([
            item.product ? item.product.veg_name : '',
            item.seller ? item.seller.name : '',
            item.detection_project,
            item.result * 1 === 1
              ? '合格'
              : item.result * 1 === 0
              ? '不合格'
              : '疑似',
            item.date
          ])
        })
        this.configData = configData
        this.config = {
          header: ['商品名称', '所属摊铺', '检测项目', '检测结果', '检测时间'],
          data: this.configData,
          headerBGC: 'rgb(32, 110, 212)',
          oddRowBGC: '',
          evenRowBGC: '',
          rowNum: 3,
          align: ['center', 'center', 'center', 'center', 'center']
        }
        this.page += 1
        if (this.page >= this.lastPage) {
          return
        }
        this.out = setTimeout(this.getDetectionInformation, 60000)
      })
    }
  },
  beforeDestroy () {
    clearTimeout(this.time)
    clearTimeout(this.rangking)
    clearTimeout(this.out)
  },
  mounted () {
    if (this.type === 5) {
      this.getRanking()
    }
    if (this.type === 6) {
      this.getRanking()
    }
  }
}
</script>
<style lang="less" scoped>
.scroll {
  width: 100%;
  height: 100%;
  padding-top: 0.05rem;
  box-sizing: border-box;
}
</style>
