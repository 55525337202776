<template>
  <div class="about flex">
    <header class="header flex">
      <border
        v-for="(item, i) in totalTitel"
        :key="i"
        :obj="item"
        style="width: 15.8%"
      ></border>
    </header>
    <section class="section flex">
      <div class="left flex">
        <div class="video flex">
          <div class="line">
            <border name="热销商品销售分析">
              <div class="scrollFlex flex">
                <scroll
                  style="width: 49.3%"
                  ref="scroll1"
                  :scrollData="scroll1"
                  :type="1"
                />
                <scroll
                  style="width: 49.3%"
                  ref="scroll2"
                  :scrollData="scroll2"
                  :type="2"
                />
              </div>
            </border>
          </div>
          <div class="line">
            <border name="市场当天交易走势">
              <lines type="about" />
            </border>
          </div>
        </div>
        <div class="footer">
          <border name="热销商品销售分析">
            <scroll :type="3" />
          </border>
        </div>
      </div>
      <div class="center flex">
        <div class="video">
          <border name="监控视屏">
            <videohls />
          </border>
        </div>
        <div class="footer">
          <border name="市场管理人员公示">
            <carousels type="about" />
          </border>
        </div>
      </div>
      <div class="right flex">
        <div class="video flex">
          <div class="pass">
            <border name="食品安全快检信息">
              <aboutChunk />
            </border>
          </div>
          <div class="ranking flex">
            <div class="rankang1">
              <border name="商户服务信用好评榜">
                <scroll :type="5" />
              </border>
            </div>
            <div class="rankang1">
              <border name="摊档环境卫生好评榜">
                <scroll :type="6" />
              </border>
            </div>
          </div>
        </div>
        <div class="footer">
          <border name="市场平面图">
            <el-image
              style="width: 100%; height: 100%"
              :src="image"
              :preview-src-list="images"
            >
            </el-image>
          </border>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import border from "@comp/About/border.vue";
import scroll from "@comp/About/scroll.vue";
import lines from "@comp/home/line.vue";
import aboutChunk from "@comp/About/chunk.vue";
import carousels from "@comp/About/carousels.vue";
import videohls from "@comp/About/videohls.vue";
import { getAboutInfoBox, getProductAnalysis, getMarketPlan } from "@api/index";
export default {
  name: "About",
  components: {
    border,
    scroll,
    lines,
    aboutChunk,
    carousels,
    videohls,
  },
  data() {
    return {
      totalTitel: [
        {
          name: "商户总数",
          img: require("@assets/aboutTitle1.png"),
          num: "125家",
          count: "",
        },
        {
          name: "设备数",
          img: require("@assets/aboutTitle2.png"),
          num: "125家",
          count: "",
        },
        {
          name: "今日交易金额",
          img: require("@assets/aboutTitle3.png"),
          num: "5154541元",
          count: "昨天:2315451元",
        },
        {
          name: "今日交易笔数",
          img: require("@assets/aboutTitle6.png"),
          num: "5154541元",
          count: "昨天:2315451元",
        },
        {
          name: "今日快检批次",
          img: require("@assets/aboutTitle6.png"),
          num: "5154541元",
          count: "昨天:215145笔",
        },
        {
          name: "今日合格批次",
          img: require("@assets/aboutTitle6.png"),
          num: "5154541元",
          count: "昨天:215145笔",
        },
      ],
      scroll1: "",
      scroll2: "",
      image: "",
      images: [],
    };
  },
  created() {
    this.getAboutInfoBox();
    this.getMarketPlan();
  },
  methods: {
    getAboutInfoBox() {
      getAboutInfoBox({ market_id: this.$route.query.id }).then((res) => {
        let totalTitel = this.totalTitel;
        totalTitel.forEach((item) => {
          switch (item.name) {
            case "商户总数":
              item.num = res.data.sellerCount + "家";
              break;
            case "设备数":
              item.num = res.data.deviceCount + "家";
              break;
            case "今日交易金额":
              item.num = res.data.todayPrice + "元";
              item.count = "昨天:" + res.data.yesterdayPrice + "元";
              break;
            case "今日交易笔数":
              item.num = res.data.todayOrderCount + "笔";
              item.count = "昨天:" + res.data.yesterdayOrderCount + "笔";
              break;
            case "今日快检批次":
              item.num = res.data.todayDetectionCount + "批";
              item.count = "昨天:" + res.data.yesterdayDetectionCount + "批";
              break;
            case "今日合格批次":
              item.num = res.data.todayQualifiedCount + "批";
              item.count = "昨天:" + res.data.yesterdayQualifiedCount + "批";
              break;
          }
        });
        this.totalTitel = totalTitel;
      });
    },
    getProductAnalysis() {
      this.$refs.scroll1.loading = true;
      this.$refs.scroll2.loading = true;
      getProductAnalysis({ market_id: this.$route.query.id }).then((res) => {
        const scroll1 = [];
        const scroll2 = [];
        res.data.orderCount.forEach((item) => {
          const arr1 = [];
          arr1.push(item.product_name, item.productCount + "笔");
          scroll1.push(arr1);
        });
        res.data.orderPrice.forEach((item) => {
          const arr2 = [];
          arr2.push(item.product_name, item.productPrice + "元");
          scroll2.push(arr2);
        });
        this.scroll1 = scroll1;
        this.scroll2 = scroll2;
        this.$refs.scroll1.loading = false;
        this.$refs.scroll2.loading = false;
      });
    },
    getMarketPlan() {
      getMarketPlan({ market_id: this.$route.query.id }).then((res) => {
        this.image = res.data.market_plan;
        this.images = [res.data.market_plan];
      });
    },
  },
  mounted() {
    this.getProductAnalysis();
  },
};
</script>
<style lang="less" scoped>
.about {
  width: 100%;
  height: calc(100% - 0.7rem);
  padding: 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  .header {
    width: 100%;
    height: 1.8rem;
  }
  .scrollFlex {
    width: 100%;
    height: 100%;
  }
  .section {
    width: 100%;
    height: calc(100% - 2rem);
    .left,
    .right {
      width: 33%;
      height: 100%;
      flex-direction: column;
    }
    .center {
      width: 33%;
      margin: 0 0.2rem;
      height: 100%;
      flex-direction: column;
    }
    .video {
      width: 100%;
      height: 66%;
      flex-direction: column;
      .line {
        width: 100%;
        height: 47.5%;
      }
      .pass {
        width: 100%;
        height: 53.5%;
      }
      .ranking {
        width: 100%;
        height: 42%;
        .rankang1 {
          width: 48%;
          height: 100%;
        }
      }
    }
    .footer {
      width: 100%;
      height: 32%;
      margin-top: 0.2rem;
    }
  }
}
</style>
